import React from "react";
import { FormItem } from "../../../models";

interface ContainerProps {
  item: FormItem;
  selectListClickedFn: (item: FormItem) => void;
}

const InfoRow: React.FC<ContainerProps> = ({ item, selectListClickedFn }) => {
  return (
    <>
      <div className="p-[20px] bg-[#f5f5f5] w-[100%] mt-3 grid grid-cols-5" onClick={() => selectListClickedFn(item)}>
        {!item.valueText && <div className="col-span-4">{item.name}</div>}
        {item.valueText && (
          <div className="col-span-4">
            <div>{item.name}</div>
            <div className="text-[13px] text-[#666] font-bold">{item.valueText}</div>
          </div>
        )}

        <div className="material-symbols-outlined !text-right">arrow_forward_ios</div>
      </div>
    </>
  );
};

export default InfoRow;
