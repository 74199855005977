import React from "react";
import ModalHeader from "../../../components/modals/singleModal/ModalHeader";
import ModalFooter from "../../../components/modals/singleModal/ModalFooter";
import CustomForm from "../../../components/custom_components/Form/CustomForm";
import { FormItemsData } from "./FormItemsData";


interface ContainerProps {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
  headerText: string;
  onSubmit: () => void;
  onChange: (e: any) => void;
}

const AddAlbumModalContainer: React.FC<ContainerProps> = ({ showModal, setShowModal, headerText, onSubmit, onChange }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*Header*/}
                <ModalHeader text={headerText} />

                {/*body*/}
                <div className="relative py-3 px-0  flex-auto">
                  <CustomForm items={FormItemsData} onChange={onChange}></CustomForm>;{/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">Modal Body</p> */}
                </div>

                {/*footer*/}
                <ModalFooter cancelText="close" okText="save" setShowModal={setShowModal} onSubmitFunction={onSubmit} />
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddAlbumModalContainer;
