import React from "react";
import { IonButtons, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../services/redux/rootReducer";

interface ContainerProps {
  text: string;
  showBack: number;
}

const IonHeaderComp: React.FC<ContainerProps> = ({ text, showBack = 0 }) => {
  const history = useHistory();
  const { selectedAlbum } = useSelector((state: RootState) => state.Albums);

  const NavigateToSinglePage = () => {
    console.log("showBack : ", showBack);
    if (showBack == 1) history.push("/page/inbox/");
    if (showBack == 2) history.push("/album/" + selectedAlbum.id);
  };

  return (
    <div className="container">
      <IonHeader>
        <div className="grid grid-cols-6 p-2 bg-white">
          {showBack > 0 && (
            <div className={!showBack ? "col-span-2" : "col-span-0"} onClick={NavigateToSinglePage}>
              <div className="material-symbols-outlined !text-right">arrow_back_ios</div>
            </div>
          )}
          <div className={showBack ? "col-span-4" : "col-span-8"}>
            <div className="w-full text-center text-[20px] text-bold">{text}</div>
          </div>
        </div>
      </IonHeader>
    </div>
  );
};

export default IonHeaderComp;
