const AddPhotoFormFields = [
  {
    name: "Caption",
    paramName: "caption",
    placeholder: "Please enter caption",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter caption",
    value: "111",
    listId: 0,
    valueText: "",
  },
  {
    name: "Camera",
    paramName: "camera",
    placeholder: "Please enter caption",
    isRequired: true,
    type: "select",
    validationType: "bigger3",
    direction: "rtl",
    error: "please choose camera option",
    value: "111",
    listId: 1,
    valueText: "",
  },
  {
    name: "Lense",
    paramName: "lense",
    placeholder: "Please enter lense",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter lense",
    value: "111",
    listId: 0,
    valueText: "",
  },
  {
    name: "Iso Rated At",
    paramName: "isoRated",
    placeholder: "Please enter iso rated",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter iso rated",
    value: "111",
    listId: 0,
    valueText: "",
  },
  {
    name: "F-Stop",
    paramName: "fStop",
    placeholder: "Please enter f-stop",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter f-stop",
    value: "111",
    listId: 0,
  },
  {
    name: "Shutter Speed",
    paramName: "shutterSpeed",
    placeholder: "Please enter shutter speed",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter shutter speed",
    value: "111",
    listId: 0,
  },
  {
    name: "Expose",
    paramName: "Expose",
    placeholder: "Please enter shutter speed",
    isRequired: true,
    type: "select",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter expose",
    value: "111",
    listId: 4,
  },
  {
    name: "Avg Between light and shadow",
    paramName: "lightAndShadow",
    placeholder: "Please enter caption",
    isRequired: true,
    type: "select",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter avg between light and shadow",
    value: "111",
    listId: 2,
  },
  {
    name: "Film",
    paramName: "film",
    placeholder: "Please enter caption",
    isRequired: true,
    type: "select",
    validationType: "bigger3",
    direction: "rtl",
    error: "please enter film",
    value: "111",
    listId: 3,
  },
];

export default AddPhotoFormFields;
