import React, { useEffect } from "react";
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useParams } from "react-router";
import MainPageBanner from "./Components/Main_Page_Banner";
import Main_Page_Sort_By from "./Components/Main_Page_Sort_By";
import IonHeaderComp from "../../components/utils/IonHeaderComp";
import Albums from "./Components/Albums";
import { GetRequest } from "../../services/api/GET_POST";
import { useDispatch, useSelector } from "react-redux";
import Settings from "../../services/utils/Settings";
import { setAlbums } from "../../services/redux/Albums_Slice";

const MainPage: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { name } = useParams<{ name: string }>();
  const showBack: number = 0;
  //console.log("ENV : ", process.env.REACT_APP_GLOBLA_URL);
  //console.log(process.env.REACT_APP_SITE);

  useEffect(() => {
    dispatch(GetRequest("getAllAlbumsById?id=" + Settings.USER_ID, setAlbums));
  }, []);

  return (
    <IonPage>
      <IonHeaderComp text="Firumu" showBack={showBack} />
      <IonContent fullscreen>
        <MainPageBanner name="My film album" />
        <Main_Page_Sort_By />
        <Albums />
      </IonContent>
    </IonPage>
  );
};

export default MainPage;
