import React, { useEffect, useState } from "react";
import { FormItem, SelectedItem } from "../../../models";
import { useDispatch, useSelector } from "react-redux";
import { addFormItemAsList, addFormItemAsSingle, getSelectListItems, resetSelectedItems } from "../../../services/redux/Albums_Slice";
import CbRow from "../components/CbRow";
import { GetRequest } from "../../../services/api/GET_POST";
import { useHistory, useParams } from "react-router";
import { RootState } from "../../../services/redux/rootReducer";
import AddPhotoFormFields from "../data/AddPhotoFormFields";
import { IonPage } from "@ionic/react";
import MainPageBanner from "../../MainPage/Components/Main_Page_Banner";

const SelectedListPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<any>();
  const wHeight = window.innerHeight - 200 + "px";
  const history = useHistory();
  const [selectedListID, setselectedListID] = useState<number>(0);
  const [listName, setlistName] = useState<string>("");
  const [changeItem, setChangeItem] = useState<FormItem>({} as FormItem);
  const [filterArray, setFilterArray] = useState<SelectedItem[]>([]);

  const { AddPhotoFormItems, selectedCBItems } = useSelector((state: RootState) => {
    //console.log("State : ", state.Albums);
    return state.Albums;
  });

  useEffect(() => {
    const item = AddPhotoFormItems.find((item) => item.listId == Number(id));
    if (item) {
      setlistName(item.name);

      if (item.value != "") {
        setselectedListID(Number(item.value));
      }
    }

    if (selectedCBItems) setFilterArray([...selectedCBItems]);
    console.log("EF : ", item, selectedCBItems);
  }, [selectedCBItems]);

  const selectedListChosen = (item: SelectedItem) => {
    const itemNum = AddPhotoFormItems.findIndex((item) => item.listId == Number(id));

    if (itemNum > -1) {
      setselectedListID(item.id);
      const changeItem = { ...AddPhotoFormItems[itemNum] };
      changeItem.value = String(item.id);
      changeItem.valueText = item.name;
      setChangeItem(changeItem);
      console.log("CHANGE : ", changeItem);
    }
  };

  const GoBack = (type: number) => {
    console.log("Change L: ", changeItem);
    if (type == 1) dispatch(addFormItemAsSingle(changeItem));
    dispatch(resetSelectedItems(""));
    setChangeItem({} as FormItem);
    history.goBack();
  };

  const onHandleSearch = (e: any) => {
    if (e.target.value == "") setFilterArray([...selectedCBItems]);
    else setFilterArray(selectedCBItems.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  return (
    <IonPage className="mt-0">
      <div>
        <MainPageBanner name="" />
        <div className="p-2">
          <input type="text" id="search" onChange={(e) => onHandleSearch(e)} className="bg-[#f5f5f5]  text-gray-900 text-sm  block w-full p-2 " placeholder="search .." required />
        </div>
        <div className="grid grid-cols-5 p-2 gap-2">
          <button className="bg-[#ccc] p-3 text-center w-full mt-0 col-span-3" onClick={() => GoBack(1)}>
            save
          </button>
          <button className="bg-[#999] p-3 text-center w-full mt-0 col-span-2" onClick={() => GoBack(0)}>
            Back
          </button>
        </div>

        <div className={"h-[" + wHeight + "]  overflow-auto"}>
          {filterArray.map((item, index) => {
            return <CbRow key={index} item={item} selectedListChosenFn={selectedListChosen} selectedListID={selectedListID} />;
          })}
        </div>
      </div>
    </IonPage>
  );
};

export default SelectedListPage;
