import { AddAlbumForm } from "../../../models";

export const checkValidateForm = (albumForm: AddAlbumForm | undefined) => {
  console.log("ALBUM : ", albumForm?.name, albumForm);
  if (albumForm?.name == "" || !albumForm?.name) {
    return "חובה להכניס שם אלבום";
  }

  if (albumForm?.albumImage == "" || !albumForm?.albumImage) {
    return "חובה להכניס תמונה";
  }

  return "";
};
