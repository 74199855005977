import { FormItem } from "../../../models";
export const FormItemsData: FormItem[] = [
  {
    name: "שם מלא",
    paramName: "name",
    placeholder: "הכנס שם מלא",
    isRequired: true,
    type: "text",
    validationType: "bigger3",
    direction: "rtl",
    error: "חובה להכניס שם תקין",
    value: "",
    listId: 0,
  },
  {
    name: "העלה תמונה",
    paramName: "albumImage",
    placeholder: "העלה תמונה",
    isRequired: true,
    type: "browse",
    validationType: "bigger3",
    direction: "rtl",
    error: "חובה להכניס תמונה",
    value: "",
    listId: 0,
  },
];

// {
//   name: "Mail",
//   placeholder: "Please enter mail",
//   isRequired: true,
//   type: "mail",
//   validationType: "mail",
//   error: "חובה להכניס מייל תקין",
// },
