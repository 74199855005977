import React, { useEffect, useState } from "react";
import { FormItem } from "../../../../models";

interface ContainerProps {
  item: FormItem;
  onChange: (e: any) => void;
}

const UploadImage: React.FC<ContainerProps> = ({ item, onChange }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      onChange(undefined);
      return;
    }

    const objectUrl: string = URL.createObjectURL(selectedFile);
    onChange(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <div>
        <input type="file" id={item.paramName} onChange={onChange} />
        {selectedFile && <img src={preview} className="w-[100px] mt-3" />}
      </div>
    </div>
  );
};

export default UploadImage;
