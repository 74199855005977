import React from "react";
import { FormItem, SelectedItem } from "../../../models";

interface ContainerProps {
  item: SelectedItem;
  selectedListChosenFn: (item: SelectedItem) => void;
  selectedListID: number;
}

const CbRow: React.FC<ContainerProps> = ({ item, selectedListChosenFn, selectedListID }) => {
  //console.log("CbRow", item, selectedListID);
  const [isClicked, setIsClicked] = React.useState(false);
  return (
    <>
      <div className="p-[20px] bg-[#f5f5f5] w-[100%] mt-3 grid grid-cols-5 " onClick={() => selectedListChosenFn(item)}>
        <div className="col-span-4">{item.name}</div>
        <div className="material-symbols-outlined !text-right">{selectedListID == item.id ? "radio_button_checked" : "radio_button_unchecked"}</div>
        {/* {isClicked && (
          <div className="material-symbols-outlined !text-right" onClick={() => setIsClicked(true)}>
            radio_button_unchecked
          </div>
        )} */}
      </div>
    </>
  );
};

export default CbRow;
