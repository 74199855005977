import React from "react";

interface ContainerProps {
  title: string;
  description: string;
}

const ImageInfoRow: React.FC<ContainerProps> = ({ title, description }) => {
  return (
    <div>
      <div className="p-[4px] bg-[#ffffff] w-[100%] mt-3 grid grid-cols-6">
        <div className="col-span-3 text-[#b2b2b2] text-[14px] font-bold p-1">{title}</div>
        <div className="col-span-3 p-1">{description}</div>
      </div>
      <div className="bg-[#b2b2b2] h-[1px] w-[100%]"></div>
    </div>
  );
};

export default ImageInfoRow;
