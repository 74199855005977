import React, { useEffect } from "react";
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/utils/Menu";
import MainPage from "./pages/MainPage/MainPage";
import SingleAlbumPage from "./pages/SingleAlbumPage/SingleAlbumPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import AddPhotoPage from "./pages/AddPhotoPage/AddPhotoPage";
import SelectedListPage from "./pages/AddPhotoPage/SelectedListPage/SelectedListPage";
import SelectedListPageTest from "./pages/AddPhotoPage/SelectedListPage/SelectedListPageTest";
import { useDispatch } from "react-redux";
import { addFormItemAsList } from "./services/redux/Albums_Slice";
import AddPhotoFormFields from "./pages/AddPhotoPage/data/AddPhotoFormFields";
import ImageInfo from "./pages/ImageInfo/ImageInfo";

setupIonicReact();

const App: React.FC = () => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    console.log("AddPhotoFormFields", AddPhotoFormFields);
    dispatch(addFormItemAsList(AddPhotoFormFields));
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/page/Inbox" />
            </Route>
            <Route path="/page/:name" exact={true}>
              <MainPage />
            </Route>
            <Route path="/album/:id" exact={true}>
              <SingleAlbumPage />
            </Route>
            <Route path="/addPhoto/:id" exact={true}>
              <AddPhotoPage />
            </Route>
            <Route path="/list/:id" exact={true}>
              <SelectedListPage />
            </Route>
            <Route path="/imageInfo/:id" exact={true}>
              <ImageInfo />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
