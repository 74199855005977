import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import InfoRow from "./components/InfoRow";
import TextRow from "./components/TextRow";
import { FormItem, Photo, SendPhotoItem } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { GetRequest, PostRequest } from "../../services/api/GET_POST";
import { addPhoto, getSelectListItems } from "../../services/redux/Albums_Slice";
import { RootState } from "../../services/redux/rootReducer";
import { useHistory } from "react-router";

const AddPhotoForm: React.FC = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const wHeight = window.innerHeight - 250 + "px";
  const { AddPhotoFormItems } = useSelector((state: RootState) => state.Albums);
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [preview, setPreview] = useState<string>("");

  useEffect(() => {
    checkFormValidate();
  }, [AddPhotoFormItems]);

  const selectListClicked = (item: FormItem) => {
    console.log("Select List Clicked", item);
    dispatch(GetRequest("getSelectListItems?id=" + item.listId, getSelectListItems));
    history.push("/list/" + item.listId);
  };

  const checkFormValidate = (status: number = 0) => {
    let isDisabled = false;
    if (AddPhotoFormItems) {
      for (const item of AddPhotoFormItems) {
        if (item.isRequired && item.value == "") {
          isDisabled = true;
          if (status == 1) {
            alert(item.error);
            break;
          }
        }
      }
    }

    setIsDisabledForm(isDisabled);
    return isDisabled;
  };

  const onImageChange = (e: any) => {
    console.log("E : ", e.target.value);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      console.log("Event : ", reader.result);
      setSelectedFile(String(reader.result));
    };
    reader.readAsDataURL(e.target.files[0]);

    //setAlbumForm((albumForm: any) => ({ ...albumForm, [e.target.id]: e.target.value }));
  };

  const onSubmit = () => {
    const photo: any = {};
    if (!checkFormValidate(1) && selectedFile != "") {
      AddPhotoFormItems.map((item) => {
        photo[item.paramName] = item.value;
      });

      photo["img"] = selectedFile;
      photo["albumId"] = id;
      dispatch(PostRequest("addPhoto", photo, addPhoto));
      history.push("/album/" + id);
    } else {
      alert("Please select an image");
    }
    console.log("Submit", AddPhotoFormItems);
  };

  const submitButtonColor = isDisabledForm ? "#ccc" : "#999";

  return (
    <div className="p-5">
      <div>
        <div>
          <input type="file" id="img" onChange={onImageChange} />
          {selectedFile && <img src={preview} className="w-[100px] mt-3" />}
        </div>

        <form className={"h-[" + wHeight + "]  overflow-auto p-0"}>
          {AddPhotoFormItems &&
            AddPhotoFormItems.map((item, index) => {
              if (item.type == "select") return <InfoRow key={index} item={item} selectListClickedFn={selectListClicked} />;
              if (item.type == "text") return <TextRow key={index} mainItem={item} />;
            })}
        </form>

        {/* <div className={"h-[" + wHeight + "]  overflow-auto"}></div> */}
        <button className={"bg-[" + submitButtonColor + "] text-[#ffffff] mt-4 p-3 text-center w-full font-bold text-[13px]"} onClick={() => onSubmit()}>
          UPLOAD
        </button>
      </div>
    </div>
  );
};

export default AddPhotoForm;
