import React from "react";
import { FormItem } from "../../../../models";

interface ContainerProps {
  item: FormItem;
}

const MailElement: React.FC<ContainerProps> = ({ item }) => {
  return (
    <div>
      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {item.name}
      </label>
      <input type="text" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={item.placeholder} required />
    </div>
  );
};

export default MailElement;
