import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../redux/store";
import axios, { AxiosError } from "axios";
import Settings from "../utils/Settings";

export const GetRequest =
  (url: string, fn: any): AppThunk =>
  async (dispatch) => {
    axios
      .get(Settings.Get_Url() + url)
      .then((answer: any) => {
        if (answer.data.status == 200) dispatch(fn(answer.data.data));
      })
      .catch((error: AxiosError) => {
        //dispatch(setAlbums(`Error: ${error.response}`));
      });
  };

export const PostRequest =
  (url: string, obj: any, fn: any): AppThunk =>
  async (dispatch) => {
    axios
      .post(Settings.GLOBAL_URL + url, obj)
      .then((answer: any) => {
        if (answer.data.status == 200) dispatch(fn(answer.data.data));
      })
      .catch((error: AxiosError) => {
        //dispatch(setAlbums(`Error: ${error.response}`));
      });
  };
