import React from "react";

interface ContainerProps {
  name: string;
  created?: string;
}

const MainPageBanner: React.FC<ContainerProps> = ({ name, created }) => {
  return (
    <div className="relative">
      <div className="z-0 relative opacity-80 hover:opacity-100">
        <img src="../assets/images/sub-header-no-text.png" className="w-[100%]" />
      </div>
      {!created && (
        <div className="absolute top-[30%] z-1 w-full text-center font-bold text-white text-[30px]">
          <div>{name}</div>
          <div>{created}</div>
        </div>
      )}
      {created && (
        <div className="absolute top-[10%] z-1 w-full text-center font-bold text-white text-[30px]">
          <div>{name}</div>
          <div className="text-[14px] mt-[-5px]">{created}</div>
        </div>
      )}
    </div>
  );
};

export default MainPageBanner;
