import React from "react";
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import IonHeaderComp from "../../components/utils/IonHeaderComp";
import MainPageBanner from "../MainPage/Components/Main_Page_Banner";
import Main_Page_Sort_By from "../MainPage/Components/Main_Page_Sort_By";
import AlbumPhotos from "./AlbumPhotos";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../services/redux/rootReducer";

const SingleAlbumPage: React.FC = () => {
  const showBack: number = 1;
  const { selectedAlbum } = useSelector((state: RootState) => state.Albums);

  return (
    <IonPage>
      <IonHeaderComp text="firumu" showBack={showBack} />
      <IonContent fullscreen>
        <MainPageBanner name={selectedAlbum.name} created={selectedAlbum.created_at} />
        <AlbumPhotos />
      </IonContent>
    </IonPage>
  );
};

export default SingleAlbumPage;
