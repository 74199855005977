import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Settings from "../../services/utils/Settings";
import { GetRequest } from "../../services/api/GET_POST";
import { updateSingleAlbum } from "../../services/redux/Albums_Slice";
import { Photo } from "../../models";
import SingleImageCube from "../MainPage/Components/SingleImageCube";
import { RootState } from "../../services/redux/rootReducer";

const AlbumPhotos: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<any>();
  const { currentAlbum, selectedAlbum, refreshPhotos } = useSelector((state: RootState) => state.Albums);
  const history = useHistory();

  useEffect(() => {
    console.log("EF : ", id, selectedAlbum.id, currentAlbum);
    if (selectedAlbum.id == parseInt(id) || refreshPhotos == true) dispatch(GetRequest("getSingleAlbumById?id=" + id, updateSingleAlbum));
  }, [selectedAlbum, refreshPhotos]);

  const NavigateToAddPhoto = () => {
    history.push("/addPhoto/" + id);
  };

  const SingleImageType = 2;
  return (
    <div className="bg-[#f5f5f5] h-full width-full">
      <div className="grid grid-cols-3 gap-1">
        {/* Add Button  */}
        <button onClick={NavigateToAddPhoto}>
          <img src="../assets/images/add-image-icon.png" className="w-[100%]" />
        </button>

        {/* Album List  */}
        {currentAlbum.map((album: Photo) => {
          return <SingleImageCube key={album.id} id={album.id} image={album.url} text={album.name} createdAt="" type={SingleImageType} />;
        })}
      </div>
    </div>
  );
};

export default AlbumPhotos;
