import React, { Component } from "react";
import MailElement from "../formsElements/MailElement";
import TextElement from "../formsElements/TextElement";
import UploadImage from "../formsElements/UploadImage";

const selectInputType = (item, onChange) => {
  switch (item.type) {
    case "text":
      return <TextElement item={item} onChange={onChange} />;
    case "mail":
      return <MailElement item={item} onChange={onChange} />;
    case "browse":
      return <UploadImage item={item} onChange={onChange} />;
    default:
      return "this is default";
  }
};

export default selectInputType;
