import React, { useEffect } from "react";

interface ContainerProps {
  text: string;
}

const ModalHeader: React.FC<ContainerProps> = ({ text }) => {
  return (
    <>
      <div className="flex items-start justify-between p-1 pl-5 w-full border-b border-solid border-blueGray-200 rounded-t text-right">
        <h3 className="text-lg font-semibold text-right w-full pr-6">{text}</h3>
      </div>
    </>
  );
};

export default ModalHeader;
