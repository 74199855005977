import React, { useEffect } from "react";

interface ContainerProps {
  cancelText: string;
  okText: string;
  setShowModal: (b: boolean) => void;
  onSubmitFunction: () => void;
}

const ModalFooter: React.FC<ContainerProps> = ({ cancelText,okText,setShowModal,onSubmitFunction }) => {
  return (
    <>
      <div className="flex items-center justify-end px-6 py-1 border-t border-solid border-blueGray-200 rounded-b">
        <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
          {cancelText}
        </button>
        <button className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => onSubmitFunction()}>
          {okText}
        </button>
      </div>
    </>
  );
};

export default ModalFooter;
