import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../services/redux/rootReducer";
import Settings from "../../../services/utils/Settings";
import { Album } from "../../../models";
import AddAlbumModalContainer from "../AddAlbumModal/AddAlbumModalContainer";
import SingleImageCube from "./SingleImageCube";
import { GetRequest } from "../../../services/api/GET_POST";
import { setAlbums } from "../../../services/redux/Albums_Slice";
import { AddAlbumForm } from "../../../models";
import { PostRequest } from "../../../services/api/GET_POST";
import { checkValidateForm } from "../AddAlbumModal/AddAlbumModalService";
import { Warning } from "../../../services/utils/Sweat";
import { addAlbum } from "../../../services/redux/Albums_Slice";

const Albums: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [albumForm, setAlbumForm] = useState<AddAlbumForm>();
  const [callAddForm, setCallAddForm] = useState<boolean>(false);
  const { albums, callAlbumsFromServer } = useSelector((state: RootState) => state.Albums);
  const dispatch = useDispatch<any>();
  const SingleImageType = 1;

  useEffect(() => {
    console.log('CallAlbumsFromServer', callAlbumsFromServer);
    if (callAlbumsFromServer == true) dispatch(GetRequest("getAllAlbumsById?id=" + Settings.USER_ID, setAlbums));
    if (callAddForm) {
      sendToServer();
    }
  }, [callAlbumsFromServer, callAddForm]);

  
  const onSubmit = () => {
    const validate = checkValidateForm(albumForm);

    if (validate == "") {
      setAlbumForm((albumForm: any) => ({ ...albumForm, userId: Settings.USER_ID }));
      setCallAddForm(true);
      setShowModal(false);
    } else {
      Warning("אירעה שגיאה בטופס", validate);
    }
  };

  const onChange = async (e: any) => {
    if (e) {
      if (e.target.id != "albumImage") {
        setAlbumForm((albumForm: any) => ({ ...albumForm, [e.target.id]: e.target.value }));
      } else {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          setAlbumForm((albumForm: any) => ({ ...albumForm, [e.target.id]: reader.result }));
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const sendToServer = async () => {
    const res = await dispatch(PostRequest("addSingleAlbum", albumForm, addAlbum));
    setCallAddForm(false);
    console.log("FinishSend", res);
  };

  return (
    <div className="bg-[#f5f5f5] h-full width-full">
      <div className="grid grid-cols-3 gap-1">
        {/* Add Button  */}
        <button onClick={() => setShowModal(true)}>
          <img src="../assets/images/add.png" className="w-[100%]" />
        </button>

        {/* Modal  */}
        <AddAlbumModalContainer showModal={showModal} setShowModal={setShowModal} onSubmit={onSubmit} onChange={onChange} headerText="הוסף אלבום" />

        {/* Album List  */}
        {albums.map((album: Album) => {
          return <SingleImageCube key={album.id} id={album.id} image={album.img} text={album.name} createdAt={album.created_at} type={SingleImageType} />;
        })}
      </div>
    </div>
  );
};

export default Albums;
