import React from "react";
import selectInputType from "./functions/SelectInputType";
import { FormItem } from "../../../models";

interface ContainerProps {
  items: FormItem[];
  onChange: (e: any) => void;
}

const CustomForm: React.FC<ContainerProps> = ({ items, onChange }) => {
  return (
    <div className="bg-white-400 mx-[30px]">
      <form className="p-0">
        {items.map((item, index) => {
          return (
            <div key={index} className="mt-3 w-[300px]">
              {selectInputType(item, onChange)}
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default CustomForm;
