import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "./store";
import axios, { AxiosError } from "axios";
import { Album, FormItem, Photo, SelectedItem } from "../../models";
import Settings from "../utils/Settings";
import { useDispatch, useSelector } from "react-redux";
import { GetRequest } from "../api/GET_POST";

type AlbumsState = {
  albums: Album[];
  callAlbumsFromServer: boolean;
  currentAlbum: Photo[];
  selectedCBItems: SelectedItem[];
  selectedAlbum: Album;
  AddPhotoFormItems: FormItem[];
  refreshPhotos: boolean;
};

const initialState: AlbumsState = {
  albums: [],
  selectedAlbum: { id: 0, name: "", created_at: "", updated_at: "", img: "" },
  callAlbumsFromServer: false,
  currentAlbum: [],
  selectedCBItems: [],
  AddPhotoFormItems: [],
  refreshPhotos: false,
};
//const dispatch = useDispatch<any>();

const albumsSlice = createSlice({
  name: "Albums",
  initialState: initialState,
  reducers: {
    setAlbums(state, action: PayloadAction<Album[]>) {
      state.callAlbumsFromServer = false;
      state.albums = action.payload;
    },
    addAlbum(state, action: PayloadAction<string>) {
      state.callAlbumsFromServer = true;
      console.log("FinishAdd ", action.payload);
    },
    updateSingleAlbum(state, action: PayloadAction<Photo[]>) {
      state.currentAlbum = action.payload;
      state.refreshPhotos = false;
      console.log("updateSingleAlbum ", action.payload);
    },
    getSelectListItems(state, action: PayloadAction<SelectedItem[]>) {
      state.selectedCBItems = action.payload;
      console.log("CurrentAlbum ", action.payload);
    },
    GetAlbumName(state, action: PayloadAction<Album>) {
      state.selectedAlbum = action.payload;
    },
    resetSelectedItems(state, action: PayloadAction<string>) {
      console.log("RESET");
      state.selectedCBItems = [];
    },
    addFormItemAsList(state, action: PayloadAction<FormItem[]>) {
      state.AddPhotoFormItems = action.payload;
    },
    addPhoto(state, action: PayloadAction<FormItem[]>) {
      console.log("AddPhoto", action.payload);
      state.refreshPhotos = true;
    },
    deletePhoto(state, action: PayloadAction<string>) {
      console.log("DeletePhoto", action.payload);
      state.refreshPhotos = true;
    },
    addFormItemAsSingle(state, action: PayloadAction<FormItem>) {
      const items = state.AddPhotoFormItems;
      const index = state.AddPhotoFormItems.findIndex((item) => item.paramName == action.payload.paramName);
      console.log("Index", index, action.payload);
      if (index > -1) {
        items[index] = action.payload;
      }
      state.AddPhotoFormItems = items;
    },
  },
});

export const { setAlbums, addAlbum, updateSingleAlbum, getSelectListItems, addPhoto, GetAlbumName, resetSelectedItems, addFormItemAsList, addFormItemAsSingle, deletePhoto } = albumsSlice.actions;
export default albumsSlice.reducer;
