import React, { useState } from "react";
import { FormItem } from "../../../models";
import { useDispatch } from "react-redux";
import { addFormItemAsSingle } from "../../../services/redux/Albums_Slice";

interface ContainerProps {
  mainItem: FormItem;
}

const TextRow: React.FC<ContainerProps> = ({ mainItem }) => {
  const dispatch = useDispatch<any>();
  const [item, setcurrenItem] = useState<FormItem>(mainItem);

  const onHandleChange = (e: any) => {
    const newItem = { ...item, value: e.target.value };
    setcurrenItem(newItem);
    dispatch(addFormItemAsSingle(newItem));
  };

  return (
    <>
      <div className="p-[10px] bg-[#f5f5f5] w-[100%] mt-3">
        <div className="!text-left  w-[100%] ">
          <input type="text" id={item.paramName} value={item.value} onChange={(e) => onHandleChange(e)} className="p-2 bg-[#f5f5f5] w-[100%] focus:outline-none" placeholder={item.placeholder} required />
        </div>
      </div>
    </>
  );
};

export default TextRow;
